import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as ReactGA from 'react-ga';

const usePageViews = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);
};

export { usePageViews };
