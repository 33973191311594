import * as React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import useFonts from 'hooks/useFonts';
import { usePageViews } from 'hooks/useAnalytics';
import useLocalizedRoute from 'hooks/useLocalizedRoute';
import AppBar from 'components/AppBar';
import ScrollTo from 'components/ScrollTo';
import Footer from 'components/Footer';
import { StoreProvider } from '../../store';
import 'theme/global.css';
import 'theme/colors.css';
import 'theme/layout.css';
import 'theme/typography.css';
import s from './styles.module.css';
const Home = React.lazy(() => import('pages/Home'));
const WhyChooseUs = React.lazy(() => import('pages/WhyChooseUs'));
const Activities = React.lazy(() => import('pages/Activities'));
const Excursions = React.lazy(() => import('pages/Excursions'));
const Activity = React.lazy(() => import('pages/Activity'));
const Excursion = React.lazy(() => import('pages/Excursion'));
const Accommodation = React.lazy(() => import('pages/Accommodation'));
const Locations = React.lazy(() => import('pages/Locations'));
const AboutUs = React.lazy(() => import('pages/AboutUs'));
const Transfers = React.lazy(() => import('pages/Transfers'));
const BoatTours = React.lazy(() => import('pages/BoatTours'));
const BoatTour = React.lazy(() => import('pages/BoatTour'));
const NotFound = React.lazy(() => import('pages/NotFound'));
const SpecialOffers = React.lazy(() => import('pages/SpecialOffers'));
const SpecialOffer = React.lazy(() => import('pages/SpecialOffer'));

const LoadingIndicator = () => 'Loading...';
if (process.env.REACT_APP_GA_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_ANALYTICS_ID);
}

const App = () => {
  const [currentRoute, setCurrentRoute] = React.useState('');
  const history = useHistory();
  useFonts(['Dosis', 'Mulish:500,800']);
  usePageViews();
  const { i18n } = useTranslation('common');
  const { getLocalizedRoute } = useLocalizedRoute('common');
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    history.push(getLocalizedRoute(currentRoute));
  };
  return (
    <div className={s.app}>
      <StoreProvider>
        <ScrollTo />
        <AppBar className={s.navigation} setCurrentRoute={setCurrentRoute} />
        <div className={s.content}>
          <React.Suspense fallback={<LoadingIndicator />}>
            <Switch>
              {i18n.languages.map(lng => (
                <React.Fragment key={lng}>
                  <Route path={getLocalizedRoute('why-choose-us-route', lng)}>
                    <WhyChooseUs />
                  </Route>
                  <Route
                    exact
                    path={`${getLocalizedRoute('activities-route', lng)}/:id`}
                  >
                    <Activity />
                  </Route>
                  <Route
                    exact
                    path={getLocalizedRoute('activities-route', lng)}
                  >
                    <Activities />
                  </Route>
                  <Route
                    exact
                    path={getLocalizedRoute('excursions-route', lng)}
                  >
                    <Excursions />
                  </Route>
                  <Route
                    exact
                    path={`${getLocalizedRoute('excursions-route', lng)}/:id`}
                  >
                    <Excursion />
                  </Route>
                  <Route path={getLocalizedRoute('accommodation-route', lng)}>
                    <Accommodation />
                  </Route>
                  <Route path={getLocalizedRoute('explore-dalmatia-route')}>
                    <Locations />
                  </Route>
                  <Route path={getLocalizedRoute('about-us-route')}>
                    <AboutUs />
                  </Route>
                  <Route path={getLocalizedRoute('transfers-route', lng)}>
                    <Transfers />
                  </Route>
                  <Route
                    exact
                    path={getLocalizedRoute('boat-tours-route', lng)}
                  >
                    <BoatTours />
                  </Route>
                  <Route
                    exact
                    path={`${getLocalizedRoute('boat-tours-route', lng)}/:id`}
                  >
                    <BoatTour />
                  </Route>
                  <Route
                    exact
                    path={getLocalizedRoute('special-offers-route', lng)}
                  >
                    <SpecialOffers />
                  </Route>
                  <Route
                    exact
                    path={`${getLocalizedRoute(
                      'special-offers-route',
                      lng
                    )}/:id`}
                  >
                    <SpecialOffer />
                  </Route>
                  <Route exact path={'/'}>
                    <Home />
                  </Route>
                </React.Fragment>
              ))}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
            <Footer changeLanguage={changeLanguage} />
          </React.Suspense>
        </div>
      </StoreProvider>
    </div>
  );
};

export default App;
