import * as React from 'react';
import cx from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import Logo from 'components/Logo';
import facebookIcon from 'assets/facebook-icon.svg';
import instagramIcon from 'assets/instagram-icon.svg';
import tikTokIcon from 'assets/tiktok-logo.svg';
import Button from 'components/Button';
import useLocalizedRoute from 'hooks/useLocalizedRoute';
import useScreenSize from 'hooks/useScreenSize';
import emitter from 'components/BookingForm/eventEmitter';
import s from './styles.module.css';

const scrollToTop = () => window.scrollTo(0, 0);

const d = new Date();
let currentYear = d.getFullYear();

const links = [
  { labelKey: 'about us', to: 'about-us-route' },
  { labelKey: 'Explore Dalmatia', to: 'explore-dalmatia-route' },
  { labelKey: 'Accommodation', to: 'accommodation-route' },
  { labelKey: 'transfers', to: 'transfers-route' },
  { labelKey: 'Activities', to: 'activities-route' },
  { labelKey: 'excursions', to: 'excursions-route' },
  { labelKey: 'boat tours', to: 'boat-tours-route' },
  { labelKey: 'special offers', to: 'special-offers-route' },
  { labelKey: 'Why choose us', to: 'why-choose-us-route' },
  {
    labelKey: 'Contact us',
    to: '#booking-form',
    onClick: () => emitter.emit(emitter.OPEN_SIDEBAR),
  },
];

const Mission = () => (
  <div className={s.mission}>
    We will organize a perfect custom lifestyle vacation for you so you can
    focus on enjoying yourself and having fun.
  </div>
);

const Legal = () => (
  <div className={s.legal}>
    Address: Jurja Šubića 5, 21310 Omiš
    <br />
    OIB: 70362099701
  </div>
);

const Links = ({ links }) => {
  const { t } = useTranslation('common');
  const { getLocalizedRoute } = useLocalizedRoute('common');
  return (
    <div className={cx(s.links)}>
      {links.map(it => (
        <Link
          className={cx('upper', s.link)}
          key={it.to}
          to={getLocalizedRoute(it.to)}
          onClick={it.onClick || scrollToTop}
        >
          <h4>{t(it.labelKey)}</h4>
        </Link>
      ))}
    </div>
  );
};

const SocialNetwork = () => {
  return (
    <div className={cx(s.social)}>
      <a
        href="https://www.facebook.com/justchilltravel/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={facebookIcon}
          className={cx(s.facebookIcon, s.socialIcon)}
          alt="Follow on Facebook"
        />
      </a>
      <a
        href="https://www.instagram.com/justchillcroatia/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={instagramIcon}
          className={s.socialIcon}
          alt="Follow on Instagram"
        />
      </a>
      <a
        href="https://www.tiktok.com/@justchilltravelcroatia"
        target="_blank"
        rel="noreferrer"
      >
        <img src={tikTokIcon} className={s.socialIcon} alt="Follow on TikTok" />
      </a>
    </div>
  );
};

const Copyright = ({ className, t }) => (
  <>
    <div className={cx(s.copyright, className)}>
      <span>Copyright {currentYear} </span>
      {t('copyright')}
    </div>
  </>
);

const Footer = ({ changeLanguage }) => {
  const { t } = useTranslation('footer');
  const { isDesktop } = useScreenSize();
  const openForm = isDesktop
    ? () => emitter.emit(emitter.FOCUS_LOCATION)
    : () => emitter.emit(emitter.OPEN_SIDEBAR);
  const formLink = isDesktop ? '#booking-form' : null;
  return (
    <footer
      className={cx([
        'white',
        isDesktop ? 'pt-xxl' : 'pt-l pl-s pr-s',
        s.footer,
      ])}
    >
      <Logo className={s.logo} />
      <Mission />
      <Legal />
      <Button
        onClick={openForm}
        to={formLink}
        type="primary"
        className={s.action}
        outline
      >
        {t('footer:book-now')}
      </Button>
      <Links links={links} />
      <SocialNetwork />
      <Copyright
        t={t}
        className={cx(['pt-m', 'center', isDesktop ? 'pb-m' : 'pb-s'])}
      />
    </footer>
  );
};

export default Footer;
