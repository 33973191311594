import common from './common.json';
import navigation from './navigation.json';
import home from './home.json';
import accommodation from './accommodation.json';
import activities from './activities.json';
import activity from './activity.json';
import locations from './locations.json';
import footer from './footer.json';
import contact from './contact.json';
import whyChooseUs from './whyChooseUs.json';
import bookingForm from './bookingForm.json';

export default {
  common,
  navigation,
  home,
  accommodation,
  activities,
  activity,
  locations,
  footer,
  contact,
  whyChooseUs,
  bookingForm
};
