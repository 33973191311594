/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import WebFont from 'webfontloader';

const useFonts = families => {
  useEffect(() => {
    WebFont.load({ google: { families } });
  }, []);
};

export default useFonts;
