import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import de from './locales/de';

const languages = [
  { locale: 'en', label: 'English' },
  { locale: 'de', label: 'German' },
];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: { en, de },
    fallbackLng: languages.map(it => it.locale),
    interpolation: { escapeValue: false },
    react: { useSuspense: true },
  });

export default i18n;
export { languages };
