import mitt from 'mitt';

const emitter = mitt();
const OPEN_SIDEBAR = 'form:open:sidebar';
const CLOSE_SIDEBAR = 'form:close:sidebar';
const FOCUS_LOCATION = 'form:focus:location';
const FOCUS_FIRST_NAME = 'form:focus:name';
const FOCUS_ACTIVITIES = 'form:focus:activities';
const FOCUS_ACCOMMODATION = 'form:focus:accommodation';
const EXTEND = 'form:extend';

export default {
  addListener(event, handler) {
    emitter.on(event, handler);
  },
  removeListener(event, handler) {
    emitter.off(event, handler);
  },
  emit(event) {
    emitter.emit(event);
  },
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  FOCUS_LOCATION,
  FOCUS_ACTIVITIES,
  FOCUS_ACCOMMODATION,
  FOCUS_FIRST_NAME,
  EXTEND,
};
