import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Button from 'components/Button/Base';
import emitter from 'components/BookingForm/eventEmitter';
import useLocalizedRoute from 'hooks/useLocalizedRoute';
import s from './styles.module.css';

const OpenButton = ({ setSidebarState, className }) => (
  <Button
    className={cx(s.open, className)}
    onClick={() => {
      emitter.emit(emitter.CLOSE_SIDEBAR);
      setSidebarState(true);
    }}
  >
    <div className={cx(s.menu)}>
      <span>Menu</span>
      <div>
        <span className={cx(s.line)}></span>
        <span className={cx(s.line)}></span>
        <span className={cx(s.line)}></span>
      </div>
    </div>
  </Button>
);

const LinkItem = ({ children, ...props }) => (
  <li>
    <NavLink
      className={cx('upper', s.link)}
      {...props}
      // activeClassName={s.activeLink}
    >
      {children}
    </NavLink>
  </li>
);

const List = ({ routes, onLinkClick, className }) => {
  const { t } = useTranslation(['navigation', 'common']);
  const { getLocalizedRoute } = useLocalizedRoute('common');
  return (
    <ul className={cx('white', className)}>
      {routes.map(({ key, to }) => (
        <LinkItem
          key={to}
          onClick={() => onLinkClick(to)}
          to={getLocalizedRoute(to)}
        >
          {t(key)}
        </LinkItem>
      ))}
    </ul>
  );
};

const Navigation = ({ routes, onLinkClick }) => {
  return (
    <Sidebar
      className={s.sidebarToggle}
      sidebarClassName={s.sidebar}
      withClose
      renderToggleButton={({ setIsOpen }) => (
        <OpenButton setSidebarState={setIsOpen} />
      )}
    >
      <List routes={routes} onLinkClick={onLinkClick} className="flex-v" />
    </Sidebar>
  );
};

export default Navigation;
