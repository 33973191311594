import React from 'react';
import curry from 'lodash/curry';

const initialState = {
  location: null,
  accommodation_type: null,
  activities: [],
  arrival_in: null,
  length_of_stay: null,
  travellers: {
    children: 0,
    adults: 0,
    pets: 0,
  },
};
const StoreContext = React.createContext();

const StoreProvider = ({ children }) => {
  const [state, setState] = React.useState(initialState);
  return (
    <StoreContext.Provider
      value={{
        state,
        setState: curry((name, value) =>
          setState(state => ({
            ...state,
            [name]: value || initialState[name],
          }))
        ),
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
