import * as React from 'react';
import cx from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import BaseButton from './Base';

import s from './styles.module.css';

const Button = ({ children, type, outline, className, ...props }) => {
  const classes = cx(
    'upper bold',
    s.button,
    {
      [s.outline]: outline,
      [s.primary]: type === 'primary',
      [s.secondary]: type === 'secondary',
      [s.link]: type === 'link',
    },
    className
  );

  return props.to ? (
    <Link {...props} className={classes}>
      {children}
    </Link>
  ) : (
    <BaseButton {...props} className={classes}>
      {children}
    </BaseButton>
  );
};

export default Button;
