import * as React from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import ReactSidebar from 'react-sidebar';
import Button from 'components/Button/Base';
import s from './styles.module.css';
import Logo from 'components/Logo';

const Close = ({ setSidebarState, className }) => (
  <Button
    className={cx(s.close, className)}
    onClick={() => setSidebarState(false)}
  >
    <span></span>
    <span></span>
  </Button>
);

const Container = ({ children, setSidebarState }) => (
  <div className={cx('flex-v pt-s pr-s pl-s', s.container)}>
    <Close setSidebarState={setSidebarState}>X</Close>
    {children}
  </div>
);

const Sidebar = ({
  children,
  renderToggleButton,
  sidebarClassName,
  overlayClassname,
  withClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { pathname } = useLocation();
  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  return (
    <>
      <ReactSidebar
        sidebar={
          withClose ? (
            <Container setSidebarState={setIsOpen}>
              <Logo className={s.logo} />
              {children}
            </Container>
          ) : (
            children
          )
        }
        rootClassName={cx(s.overlay, { [s.active]: isOpen })}
        sidebarClassName={cx(s.sidebar, sidebarClassName)}
        overlayClassName={cx(s.overlay, overlayClassname)}
        open={isOpen}
        onSetOpen={setIsOpen}
      >
        <span />
      </ReactSidebar>
      {renderToggleButton({ setIsOpen, isOpen })}
    </>
  );
};

export default Sidebar;
