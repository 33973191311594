import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useLocalizedRoute from '../../hooks/useLocalizedRoute';
import logoBig from 'assets/logo-big.svg';

const Logo = ({ onClick, className }) => {
  const { getLocalizedRoute } = useLocalizedRoute('common');
  return (
    <Link
      className={cx('flex-h align-center', className)}
      onClick={onClick}
      to={getLocalizedRoute('')}
    >
      <img src={logoBig} alt="Just Chill logo" />
    </Link>
  );
};

export default Logo;
