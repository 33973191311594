import * as React from 'react';
import cx from 'classnames';
import s from './base.module.css';

const Button = ({ children, className, type = 'submit', ...rest }) => (
  <button {...rest} type={type} className={cx(s.base, className)}>
    {children}
  </button>
);

export default Button;
