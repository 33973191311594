import * as React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Sidebar from 'components/Sidebar';
import Button from 'components/Button';
import BaseButton from 'components/Button/Base';
import emitter from 'components/BookingForm/eventEmitter';
import useScreenSize from 'hooks/useScreenSize';
import { StoreContext } from 'store/index.js';
import s from './styles.module.css';

const BookingForm = () => {
  const { t } = useTranslation('bookingForm');
  const { state, setState } = React.useContext(StoreContext);
  return (
    <form
      className={s.bookingForm}
      action="https://formcarry.com/s/LJdd2PVirxBj"
      method="POST"
    >
      <p className={s.bookingFormSlogan}>{t('booking-form-sidebar-slogan')}</p>
      <div>
        <input type="hidden" name="_gotcha" />
        <input type="hidden" name="u" value="4b876eeb071e2d521aa0a729b" />
        <input type="hidden" name="id" value="597452ab04" />

        <label
          className={cx(
            'flex-v mt-s mr-s ml-s pt-s pb-s pl-s pr-s justify-center',
            s.field,
            s.text
          )}
        >
          <span className={cx({ [s.hidden]: !!state.firstName }, 'required')}>
            First name
          </span>
          <input
            name="FIRST_NAME"
            value={state.firstName ? state.firstName : ''}
            onChange={e => setState('firstName')(e.target.value)}
            required
            className={s.input}
          />
        </label>

        <label
          className={cx(
            'flex-v mt-s mr-s ml-s pt-s pb-s pl-s pr-s justify-center',
            s.field,
            s.text
          )}
        >
          <span className={cx({ [s.hidden]: !!state.lastName })}>
            Last name
          </span>
          <input
            name="LAST_NAME"
            value={state.lastName ? state.lastName : ''}
            onChange={e => setState('lastName')(e.target.value)}
            required
            className={s.input}
          />
        </label>

        <label
          className={cx(
            'flex-v mt-s mr-s ml-s pt-s pb-s pb-s pl-s pr-s justify-center',
            s.field,
            s.text
          )}
        >
          <span className={cx({ [s.hidden]: !!state.email })}>Email</span>
          <input
            type="email"
            name="EMAIL"
            value={state.email ? state.email : ''}
            onChange={e => setState('email')(e.target.value)}
            className={s.input}
            required
          />
        </label>

        <label
          className={cx(
            'flex-v mt-s mr-s ml-s pt-s pb-s pb-s pl-s pr-s justify-center',
            s.field,
            s.text
          )}
        >
          <span className={cx({ [s.hidden]: !!state.phone })}>Phone</span>
          <input
            type="phone"
            name="PHONE"
            value={state.phone ? state.phone : ''}
            onChange={e => setState('phone')(e.target.value)}
            className={s.input}
            required
          />
        </label>

        <label
          className={cx(
            'flex-v mt-s mr-s ml-s pt-s pb-s pl-s pr-s justify-center',
            s.field,
            s.text
          )}
        >
          <span
            className={cx({ [s.hidden]: !!state.message }, 'textarea required')}
          >
            Message
          </span>
          <textarea
            rows={5}
            name="MESSAGE"
            value={state.message ? state.message : ''}
            onChange={e => setState('message')(e.target.value)}
            className={s.input}
            required
          />
        </label>
      </div>
      <BaseButton
        name="submit"
        type="submit"
        value="subscribe"
        className={cx('upper', s.sendButton)}
      >
        {t('Send request')}
      </BaseButton>
    </form>
  );
};

const SidebarTrigger = ({ setIsOpen, isOpen, children }) => {
  React.useEffect(() => {
    const openSidebar = () => setIsOpen(true);
    const closeSidebar = () => setIsOpen(false);
    emitter.addListener(emitter.OPEN_SIDEBAR, openSidebar);
    emitter.addListener(emitter.CLOSE_SIDEBAR, closeSidebar);
    return () => {
      emitter.removeListener(emitter.OPEN_SIDEBAR, openSidebar);
      emitter.removeListener(emitter.CLOSE_SIDEBAR, closeSidebar);
    };
  });
  return (
    <Button
      onClick={() => setIsOpen(!isOpen)}
      className={cx('upper', s.actionButton, { [s.close]: isOpen })}
    >
      {children}
    </Button>
  );
};

const BookingFormSidebar = () => {
  const { t } = useTranslation('bookingForm');
  const { isDesktop } = useScreenSize();

  return isDesktop ? (
    <Button
      onClick={() => emitter.emit(emitter.FOCUS_FIRST_NAME)}
      className={cx('flex-h align-center upper', s.actionButton)}
    >
      {t('navigation:book-now')}
    </Button>
  ) : (
    <Sidebar
      sidebarClassName={s.sidebar}
      overlayClassname={s.overlay}
      renderToggleButton={props => (
        <SidebarTrigger {...props}>{t('navigation:book-now')}</SidebarTrigger>
      )}
    >
      <BookingForm />
    </Sidebar>
  );
};

export default BookingFormSidebar;
