import * as React from 'react';
import cx from 'classnames';
import Logo from 'components/Logo';
import BookingFormSidebar from 'components/BookingFormSidebar';
import Navigation from './Navigation';
import s from './styles.module.css';

const routes = [
  { key: 'home', to: 'home-route' },
  { key: 'about us', to: 'about-us-route' },
  { key: 'explore dalmatia', to: 'explore-dalmatia-route' },
  { key: 'accommodation', to: 'accommodation-route' },
  { key: 'transfers', to: 'transfers-route' },
  { key: 'activities', to: 'activities-route' },
  { key: 'excursions', to: 'excursions-route' },
  { key: 'boat tours', to: 'boat-tours-route' },
  { key: 'special offers', to: 'special-offers-route' },
  { key: 'why choose us', to: 'why-choose-us-route' },
];

const AppBar = ({ setCurrentRoute, className }) => {
  return (
    <header
      className={cx('flex-h', 'justify-space-between', s.header, className)}
    >
      <Logo onClick={() => setCurrentRoute('')} />
      <nav className={cx('flex-h', s.nav)}>
        <Navigation routes={routes} onLinkClick={setCurrentRoute} />
        <BookingFormSidebar />
      </nav>
    </header>
  );
};

export default AppBar;
