import { useTranslation } from 'react-i18next';

const useLocalizedRoute = (...params) => {
  const { t, i18n } = useTranslation(...params);
  const getLocalizedRoute = (route, lng = i18n.language) =>
    `/${t(route, { lng })}`;
  return { getLocalizedRoute };
};

export default useLocalizedRoute;
